<template>
    <!--编辑页与详情页相同-->

    <div class="SelfInEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            :model="form"
            :rules="rules"
            ref="editForm"
            size="small"
            label-width="80px"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构类型">
                            <el-input v-model="formExtend.deptTypeName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="进货机构">
                            <el-input v-model="formExtend.deptName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="供应商">
                            <el-input v-model="formExtend.supplierName" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input v-model="search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input v-model="formExtend.createTime" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="formExtend.creator" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <ef-remark v-model="form.remark" />
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" @click="queryAndAdd" size="small" :disabled="disableQueryAndAddButton"
                    >查询并添加</el-button
                >
                <el-button type="primary" @click="showPickUpGoods" size="small" :disabled="disableShowPickUpGoodsDialog"
                    >选择商品基本信息</el-button
                >
                <el-button type="primary" @click="handleSave" size="small" :disabled="disableSaveButton"
                    >保存</el-button
                >
            </el-card>
            <pick-up-goods ref="pickUpGoods" :selfCanClose="false" @queryGoods="setSelectedGoods" />
            <el-card shadow="never" style="margin-top: 8px">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="450"
                >
                    <el-table-column label="序号" type="index" width="50" align="center" fixed="left" />
                    <el-table-column label="商品名称" width="180" fixed="left">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="条码" width="150">
                        <template slot-scope="scope">
                            {{
                                skuByGoodsCode(scope.row.goodsCode)
                                    .bars.map((d) => d)
                                    .join('/')
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column label="商品类型" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).type | goodsType }}
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                        </template>
                    </el-table-column>
                    <el-table-column label="类目" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).category }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                        </template>
                    </el-table-column>
                    <el-table-column label="采购价" width="150" v-if="showInPrice()">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showPrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input
                                    v-model="scope.row.showPrices"
                                    :precision="showPricePrecision"
                                    @change="computeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <template v-if="enabledBigUnit">
                        <el-table-column label="包装因子" width="80">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.packFactors'"
                                    :rules="rules.packFactors"
                                >
                                    {{ scope.row.packFactors }}
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="采购箱数" min-width="120">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.bigUnitCounts'"
                                    :rules="rules.bigUnitCounts"
                                >
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.bigUnitCounts"
                                        @change="computeCountAndMoney(scope.row)"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="散装数量" min-width="120">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.bulkCounts'"
                                    :rules="rules.bigUnitCounts"
                                >
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.bulkCounts"
                                        :sku-type="skuByGoodsCode(scope.row.goodsCode).type"
                                        @change="computeCountAndMoney(scope.row)"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column label="采购总数" :render-header="rendHeader1" min-width="120">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.counts'" :rules="rules.counts">
                                <ef-stock-biz-count-input
                                    v-model="scope.row.counts"
                                    :sku-type="skuByGoodsCode(scope.row.goodsCode).type"
                                    :disabled="enabledBigUnit"
                                    @change="computeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="采购金额" :render-header="rendHeader2" min-width="100" v-if="showInPrice()">
                        <template slot-scope="scope">
                            {{ scope.row.purchaseShowMoney }}
                        </template>
                    </el-table-column>
                    <el-table-column label="赠品数量" width="120">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.giftCounts'" :rules="rules.counts">
                                <ef-stock-biz-count-input
                                    v-model="scope.row.giftCounts"
                                    :sku-type="skuByGoodsCode(scope.row.goodsCode).type"
                                    @change="computeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="总计数量" width="120">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.totalCounts'"
                                :rules="rules.totalCounts"
                            >
                                <ef-stock-biz-count-input
                                    v-model="scope.row.totalCounts"
                                    :sku-type="skuByGoodsCode(scope.row.goodsCode).type"
                                    :disabled="true"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="生产日期" width="170" min-width="120">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.produceDates'"
                                :rules="rules.produceDates"
                            >
                                <el-date-picker
                                    v-model="scope.row.produceDates"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions"
                                    size="mini"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="80">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
    </div>
</template>
<script>
import Util from 'js/Util';
import PickUpGoods from 'components/PickUpGoods';
import MoneyUtils from 'js/MoneyUtils';
import EfRemark from 'components/EfRemark';
import EfPriceInput from 'components/EfPriceInput';
import EfStockBizCountInput from 'components/EfStockBizCountInput';
import StockBizCommon, { factoryPrice, packFactor, PropertyConverter, wholeSalePrice } from 'js/StockBizCommon';
import {
    deptCodeValidateRule,
    goodsCodeValidateRule,
    packFactorsValidateRule,
    pricesValidateRule,
    remarkNotRequiredValidateRule,
} from 'js/validate/ValidateCommonRule';
import { countGreaterThanZeroValidateRule, countValidateRule } from 'js/validate/ValidateStockBizRule';
import { get, patchByFormDataAfterConfirmed } from 'request/http';
import { DeptTypeEnum } from 'js/DeptCommon';

export default {
    name: 'SelfInEdit',
    components: { PickUpGoods, EfRemark, EfStockBizCountInput, EfPriceInput },
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            formExtend: {
                createTime: '',
                creator: '',
                deptName: '',
                deptTypeName: '',
                deptType: null,
                supplierName: '',
            },
            repoDeptFlag: false,
            showPricePrecision: 2,
            enabledBigUnit: false,
            search: '',
            baseUrl: '/purchase/selfIn',
            form: {
                deptCode: '',
                supplierCode: '',
                remark: '',
                detailParams: [],
                delCodes: [],
            },
            //所选商品详情列表
            goodsDetailMap: new Map(),
            rules: {
                deptCode: deptCodeValidateRule(),
                remark: remarkNotRequiredValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                packFactors: packFactorsValidateRule(),
                showPrices: null,
                counts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
                bigUnitCounts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
                totalCounts: [countGreaterThanZeroValidateRule()],
                produceDates: { required: true, message: '请选择生产日期', trigger: ['blur'] },
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
        };
    },
    mounted() {
        (async () => {
            //查询数据
            const rst = await get(`${this.baseUrl}/info_c/${this.code}`);
            Util.copyProperties(rst, this.form);
            Util.copyProperties(rst, this.formExtend);
            //是否启用大单位权限
            this.enabledBigUnit = await this.$efApi.deptPrivilegeApi.hasDeptPrivilege(
                this.form.deptCode,
                'dept.biz.goods.allow.bigUnit'
            );
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                rst,
                this.detailParamPropertyArrForShow()
            );
            this.goodsDetailMap = arr.goodsDetails;
            this.form.detailParams = arr.detailParams;
        })();
    },
    watch: {
        'formExtend.deptType': {
            handler(n, o) {
                this.repoDeptFlag = n === DeptTypeEnum.REPOSITORY;
                //单价精度
                this.showPricePrecision = this.repoDeptFlag ? 3 : 2;
                //更改价格验证规则
                this.rules.showPrices = pricesValidateRule(this.showPricePrecision);
            },
            immediate: true,
        },
    },
    computed: {
        //禁用/启用按钮
        disableQueryAndAddButton() {
            return Util.isEmpty(this.form.deptCode) || Util.isEmpty(this.search);
        },
        //禁用/启用按钮
        disableShowPickUpGoodsDialog() {
            return Util.isEmpty(this.form.deptCode);
        },
        //禁用/启用按钮
        disableSaveButton() {
            return (
                Util.isEmpty(this.form.deptCode) ||
                this.form.detailParams == null ||
                this.form.detailParams.length === 0
            );
        },
    },
    methods: {
        detailParamPropertyArrForShow() {
            const rst = [
                'codes',
                'goodsCode',
                'counts',
                'giftCounts',
                'produceDates',
                new PropertyConverter('prices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToThree(price);
                }),
            ];
            if (this.enabledBigUnit) {
                rst.push(
                    new PropertyConverter('packFactors', 'packFactors', (packFactors, detailParam) => {
                        return Util.isEmpty(packFactors) ? 1 : packFactors;
                    }),
                    new PropertyConverter('bigUnitCounts', 'bigUnitCounts', (bigUnitCounts, detailParam) => {
                        return Util.isEmpty(bigUnitCounts)
                            ? Math.floor(detailParam.counts / detailParam.packFactors)
                            : bigUnitCounts;
                    }),
                    new PropertyConverter('bulkCounts', 'bulkCounts', (bulkCounts, detailParam) => {
                        return this.decimal(detailParam.counts)
                            .sub(this.decimal(detailParam.bigUnitCounts).mul(detailParam.packFactors))
                            .toNumber();
                    })
                );
            }
            rst.push(
                new PropertyConverter(null, 'purchaseShowMoney', (none, detailParam) => {
                    const purchaseShowMoney = MoneyUtils.moneyToDb(
                        this.decimal(detailParam.counts)
                            .mul(detailParam.showPrices || 0)
                            .toNumber()
                    );
                    return this.repoDeptFlag
                        ? MoneyUtils.moneyToThree(purchaseShowMoney)
                        : MoneyUtils.moneyToYuan(purchaseShowMoney);
                }),
                new PropertyConverter(null, 'totalCounts', (none, detailParam) => {
                    return this.decimal(detailParam.counts).add(detailParam.giftCounts).toNumber();
                })
            );
            return rst;
        },
        detailParamPropertyArrForAdd() {
            const rst = [
                'goodsCode',
                new PropertyConverter(null, 'codes'),
                new PropertyConverter(null, 'counts'),
                new PropertyConverter(null, 'giftCounts', (count) => {
                    //赠品初始值设置为0
                    return 0;
                }),
                new PropertyConverter(this.repoDeptFlag ? factoryPrice : wholeSalePrice, 'showPrices', (price) => {
                    return MoneyUtils.moneyToThree(price);
                }),
            ];
            if (this.enabledBigUnit) {
                rst.push(
                    new PropertyConverter(null, 'bigUnitCounts'),
                    new PropertyConverter(null, 'bulkCounts', () => {
                        return 0;
                    }),
                    new PropertyConverter(packFactor, 'packFactors', (packFactor) => {
                        return Util.isEmpty(packFactor) ? 1 : packFactor;
                    })
                );
            }
            return rst;
        },
        computeCountAndMoney(row) {
            //计算数量和金额
            let counts;
            if (this.enabledBigUnit) {
                //启用大单位
                let bulkCounts = row.bulkCounts || 0;
                const packFactors = row.packFactors || 1;
                let bigUnitCounts = row.bigUnitCounts || 0;
                const bigUnitCountsForBulkCounts = Math.floor(bulkCounts / packFactors);
                if (bigUnitCountsForBulkCounts > 0) {
                    bigUnitCounts = this.decimal(bigUnitCounts).add(bigUnitCountsForBulkCounts).toNumber();
                    bulkCounts = this.decimal(bulkCounts)
                        .sub(this.decimal(bigUnitCountsForBulkCounts).mul(packFactors))
                        .toNumber();
                    row.bulkCounts = bulkCounts;
                    row.bigUnitCounts = bigUnitCounts;
                }
                counts = this.decimal(packFactors).mul(bigUnitCounts).add(bulkCounts).toNumber();
            } else {
                counts = row.counts || 0;
            }
            row.counts = counts;
            const purchaseShowMoney = MoneyUtils.moneyToDb(
                this.decimal(counts || 0)
                    .mul(row.showPrices || 0)
                    .toNumber()
            );
            row.purchaseShowMoney = this.repoDeptFlag
                ? MoneyUtils.moneyToThree(purchaseShowMoney)
                : MoneyUtils.moneyToYuan(purchaseShowMoney);
            //计算总数量
            row.totalCounts = this.decimal(counts)
                .add(row.giftCounts || 0)
                .toNumber();
        },
        showPickUpGoods() {
            this.$refs.pickUpGoods.show(this.pickUpGoodsQueryParam(), this.formExtend.deptName);
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ ...this.pickUpGoodsQueryParam(), search: this.search });
            this.setSelectedGoods(rst.data);
        },
        pickUpGoodsQueryParam() {
            return {
                deptCode: this.form.deptCode,
                goodsStatusIn: [0, 1],
                skuSinglePurchaseFlag: 1,
                supplierCode: this.form.supplierCode,
                page: 1,
                limit: 100,
            };
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        handleDelete(index) {
            const detailCode = this.form.detailParams.splice(index, 1)[0].codes;
            if (Util.isEmpty(detailCode)) {
                //不存在，说明是单据详情中新增的商品被删除，则不需要处理
                return;
            }
            this.form.delCodes.push(detailCode);
        },
        setSelectedGoods(selectedGoods) {
            const oldDetails = Object.assign([], this.form.detailParams || []);
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                oldDetails,
                this.detailParamPropertyArrForAdd()
            );
            if (oldDetails.length <= 150) {
                this.form.detailParams = oldDetails;
                this.$refs.pickUpGoods.dialogFormVisible = false;
            } else {
                this.$message.warning('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
            }
        },
        handleSave() {
            this.$refs.editForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                const form = this.form;
                const detailParams = form.detailParams;
                if (detailParams.length > 150) {
                    this.$message.error('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
                    return false;
                }
                const params = {
                    deptCode: form.deptCode,
                    supplierCode: form.supplierCode,
                    remark: form.remark,
                    delCodes: form.delCodes,
                    codes: detailParams.map((r) => r.codes),
                    counts: detailParams.map((r) => r.counts),
                    goodsCode: detailParams.map((r) => r.goodsCode),
                    giftCounts: detailParams.map((r) => r.giftCounts),
                    packFactors: detailParams.map((r) => r.packFactors),
                    produceDates: detailParams.map((r) => r.produceDates),
                    prices: detailParams.map((r) => MoneyUtils.moneyToDb(r.showPrices)),
                };
                await patchByFormDataAfterConfirmed(`${this.baseUrl}/patchExtend/${this.code}`, params);
                this.goBackAndReload();
            });
        },
        rendHeader1(h, { column }) {
            const _this = this;
            return h(
                'div',
                {
                    style: 'display:flex;',
                },
                [
                    h('span', column.label),
                    _this.enabledBigUnit
                        ? h('prompt-message', {
                              props: { messages: ['采购总数 = 采购箱数 * 包装因子 + 散装数量'] },
                          })
                        : null,
                ]
            );
        },
        rendHeader2(h, { column }) {
            const _this = this;
            return h(
                'div',
                {
                    style: 'display:flex;',
                },
                [
                    h('span', column.label),
                    _this.enabledBigUnit
                        ? h('prompt-message', {
                              props: { messages: ['采购金额 = 采购价 * 采购总数'] },
                          })
                        : null,
                ]
            );
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
